import { Link } from 'gatsby';
import * as React from 'react';

const NotAuthenticated = () => (
	<div className="centered">
		<h1 className="mb-2 text-4xl">401: Not authenticated</h1>
		<Link to="/">Back to Main</Link>
	</div>
);

export default NotAuthenticated;
